import React from 'react';
import Layout from '../components/layout';
import Link from 'gatsby-link';

import ImgEmpower from '../img/empower-women-logo.png';
import ImgHealthVid from '../img/sponsor-health-ed-video.png';
import ImgMom from '../img/sponsor-a-mom.png';
import ImgStaff from '../img/sponsor-staff-education.png';

const FOLLOWERS_COUNT = "20,441";

export default function Template({ data }) {
  return (
    <Layout nav="colored-nav">
      <div className="women-container">
        <img className="women-logo" src={ImgEmpower} alt="empower-women-logo" />
        <div className="women-how">
          <h1 className="women-header">HERE'S HOW:</h1>
          <section className="women-section">
            <img className="women-icon" src={ImgMom} alt="sponsor-mom" />
            <div className="women-info">
              <h4 className="women-title">SPONSOR A MOM FOR $35</h4>
              <hr />
              <p className="women-paragraph">
                Ensure a healthy mom and baby by sponsoring her pregnancy journey. Thank you for
                being a part of hopeful hearts!
              </p>
            </div>
            <Link to="/sponsor-a-mom">
              <button className="women-btn">SPONSOR A MOM</button>
            </Link>
          </section>
          <section className="women-section">
            <img className="women-icon" src={ImgStaff} alt="sponsor-mom" />
            <div className="women-info">
              <h4 className="women-title">SPONSOR STAFF EDUCATION FOR $50</h4>
              <hr />
              <p className="women-paragraph">
                Enhance our staff education during these unique times as we improve our skill in
                saving lives.
              </p>
            </div>
            <button type="button" data-formid="15756" className="women-btn">
              SPONSOR EDUCATION
            </button>
          </section>
          <section className="women-section">
            <img className="women-icon" src={ImgHealthVid} alt="sponsor-mom" />
            <div className="women-info">
              <h4 className="women-title">SPONSOR A HEALTH EDUCATION VIDEO FOR $20</h4>
              <hr />
              <p className="women-paragraph">
                Empowering mothers to have knowledge about pregnancy and parenting. Now more than
                ever, giving them education online in their language has never been more successful.
                Glory Reborn has over {FOLLOWERS_COUNT} followers, a platform to share our health and hope with
                the nation. Sponsorship money goes to supplies used for demonstrations.{' '}
                <i>With the option for your name or company name to be featured in our video.</i>
              </p>
            </div>
            <button type="button" data-formid="15775" className="women-btn">
              SPONSOR A VIDEO
            </button>
          </section>
        </div>
      </div>
    </Layout>
  );
}
